import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Page } from "../components/Page";
import { Title } from "../components/Title";

const NotFoundPage = () => {
  return (
    <Page page="404">
      <Row>
        <Col>
          <Title>Sivua ei valitettavasti löytynyt</Title>
          <Title>Sidan hittades inte</Title>
          <Title>Page not found</Title>
        </Col>
      </Row>
    </Page>
  );
};

export default NotFoundPage;
